<template>
  <button
    class="px-3 py-2 rounded-lg font-semibold transition-medium transition-all ease-in-out min-w-32 focus:outline-none
    flex items-center justify-center"
    :class="{
      'bg-primary-500 text-white hover:bg-primary-600 focus:shadow-xl': theme === 'primary' && !disabled,
      'bg-secondary-200 text-secondary-600 hover:bg-secondary-100 focus:shadow-xl': theme === 'secondary' && !disabled,
      'bg-gray-300 text-gray-800 hover:bg-gray-400 focus:shadow-xl': theme === 'tertiary' && !disabled,
      'bg-red-200 text-red-800 hover:bg-red-300 focus:shadow-xl': theme === 'alert' && !disabled,
      'bg-gray-300 text-gray-500 cursor-not-allowed': disabled,
    }"
    :type="type"
    @click="(e) => loading || disabled ? null : $emit('click', e)"
  >
    <slot v-if="!loading" />
    <LoadingDualRing v-else />
  </button>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'tertiary',
      validator: value => ['primary', 'secondary', 'tertiary', 'alert'].indexOf(value) !== -1,
    },
    type: {
      type: String,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
