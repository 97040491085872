import Vue from 'vue';

// Components
import Button from './Button.vue';
import Icon from './Icon.vue';
import BoxSelect from './Form/BoxSelect.vue';
import TextField from './Form/TextField.vue';
import SelectField from './Form/SelectField.vue';
import SegmentControl from './Form/SegmentControl.vue';

// Register
Vue.component('Button', Button);
Vue.component('Icon', Icon);
Vue.component('BoxSelect', BoxSelect);
Vue.component('TextField', TextField);
Vue.component('SegmentControl', SegmentControl);
Vue.component('SelectField', SelectField);
