var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"px-3 py-2 rounded-lg font-semibold transition-medium transition-all ease-in-out min-w-32 focus:outline-none flex items-center justify-center",class:{
    'bg-primary-500 text-white hover:bg-primary-600 focus:shadow-xl': _vm.theme === 'primary' && !_vm.disabled,
    'bg-secondary-200 text-secondary-600 hover:bg-secondary-100 focus:shadow-xl': _vm.theme === 'secondary' && !_vm.disabled,
    'bg-gray-300 text-gray-800 hover:bg-gray-400 focus:shadow-xl': _vm.theme === 'tertiary' && !_vm.disabled,
    'bg-red-200 text-red-800 hover:bg-red-300 focus:shadow-xl': _vm.theme === 'alert' && !_vm.disabled,
    'bg-gray-300 text-gray-500 cursor-not-allowed': _vm.disabled,
  },attrs:{"type":_vm.type},on:{"click":(e) => _vm.loading || _vm.disabled ? null : _vm.$emit('click', e)}},[(!_vm.loading)?_vm._t("default"):_c('LoadingDualRing')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }