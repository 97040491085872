<template>
  <div class="flex w-full bg-secondary-100 relative items-center p-1 rounded-lg overflow-hidden">
    <button
      v-for="(option) in options"
      :key="option.value"
      class="inline-flex flex-grow justify-center relative z-10 transition-all transition-fast focus:outline-none px-4 py-1
      font-medium rounded-lg align-middle text-sm sm:w-32 sm:px-3"
      :class="{
        'hover:opacity-50': option.value !== value,
        'text-white bg-primary-500': option.value === value,
      }"
      @click="onClick(option)"
    >
      {{ option.label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      validator: (value) => {
        let valid = true;
        value.map((option) => {
          if (!option.value || !option.label) valid = false;
        });
        return valid;
      },
    },
  },

  methods: {
    onClick(option) {
      this.$emit('input', option.value);
    },
  },
};
</script>
