<template>
  <i :class="`rec-icon rec-icon-${name}`" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
};
</script>
