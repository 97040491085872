<template>
  <div class="mb-4">
    <label
      v-if="label"
      :for="id"
      class="font-medium tracking-wider text-gray-700 block mb-1 select-none"
      :class="{
        'cursor-not-allowed': !hasError && (readonly || disabled),
      }"
    >
      {{ label }}<span v-if="required">*</span>
    </label>
    <input
      :id="id"
      ref="input"
      class="p-2 w-full rounded-lg border transition-fast transition-all ease-in-out appearance-none
      focus:border-secondary-500 focus:outline-none focus:shadow-outline"
      :class="{
        'bg-white border-gray-400': !hasError && !readonly && !disabled,
        'bg-red-100 border-red-500': hasError && !readonly && !disabled,
        'bg-gray-200 border-gray-400 cursor-not-allowed': !hasError && readonly && !disabled,
        'bg-gray-200 border-gray-400 text-gray-600 cursor-not-allowed select-none': !hasError && !readonly && disabled,
      }"
      :value="value"
      :placeholder="!label && required ? `${placeholder}*` : placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :type="type"
      :required="required"
      :min="min"
      :max="max"
      @change="onChange"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
    >
    <span
      class="text-sm px-2 text-red-500 transition-fast transition-all ease-in-out overflow-hidden block"
      :class="{
        'h-0': !hasError,
        'h-5': hasError
      }"
    >
      {{ hasError }}
    </span>
  </div>
</template>

<script>
import nanoid from 'nanoid';

export default {
  props: {
    id: {
      type: String,
      default: () => nanoid(),
    },
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['color', 'date', 'datetime-local', 'email', 'month', 'number', 'range', 'search', 'tel', 'time',
        'url', 'week', 'file', 'image', 'number', 'password', 'search', 'tel', 'text'].indexOf(value) !== -1,
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      htmlError: false,
    };
  },

  computed: {
    hasError() {
      if (this.htmlError) return this.htmlError;
      return false;
    },
  },

  mounted() {
    if (this.autofocus) this.$nextTick(() => { this.focus(); });
  },

  methods: {
    onChange() {
      this.$emit('change', this.$refs.input.value);
      if (this.htmlError) this.checkValidity();
    },
    onInput() {
      this.$emit('input', this.$refs.input.value);
      if (this.htmlError) this.checkValidity();
    },
    onBlur(e) {
      this.$emit('blur', e);
      this.checkValidity();
    },
    onFocus(e) {
      this.$emit('focus', e);
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    checkValidity() {
      this.htmlError = this.$refs.input.validationMessage;
    },
  },
};
</script>
