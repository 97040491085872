var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4"},[(_vm.label)?_c('label',{staticClass:"font-medium tracking-wider text-gray-700 block mb-1 select-none",class:{
      'cursor-not-allowed': !_vm.hasError && (_vm.readonly || _vm.disabled),
    },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('input',{ref:"input",staticClass:"p-2 w-full rounded-lg border transition-fast transition-all ease-in-out appearance-none focus:border-secondary-500 focus:outline-none focus:shadow-outline",class:{
      'bg-white border-gray-400': !_vm.hasError && !_vm.readonly && !_vm.disabled,
      'bg-red-100 border-red-500': _vm.hasError && !_vm.readonly && !_vm.disabled,
      'bg-gray-200 border-gray-400 cursor-not-allowed': !_vm.hasError && _vm.readonly && !_vm.disabled,
      'bg-gray-200 border-gray-400 text-gray-600 cursor-not-allowed select-none': !_vm.hasError && !_vm.readonly && _vm.disabled,
    },attrs:{"id":_vm.id,"placeholder":!_vm.label && _vm.required ? `${_vm.placeholder}*` : _vm.placeholder,"disabled":_vm.disabled,"readonly":_vm.readonly,"type":_vm.type,"required":_vm.required,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.value},on:{"change":_vm.onChange,"input":_vm.onInput,"blur":_vm.onBlur,"focus":_vm.onFocus}}),_c('span',{staticClass:"text-sm px-2 text-red-500 transition-fast transition-all ease-in-out overflow-hidden block",class:{
      'h-0': !_vm.hasError,
      'h-5': _vm.hasError
    }},[_vm._v(" "+_vm._s(_vm.hasError)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }