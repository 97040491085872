<template>
  <button
    class="flex flex-wrap w-40vw sm:w-64 justify-center items-center rounded-lg shadow p-3 mx-2 sm:mx-4 mb-4 lg:p-6 border-4
    transition transition-medium focus:outline-none"
    :class="{
      'bg-white border-secondary-500 shadow-2xl': selectValue === value,
      'bg-white border-white hover:shadow-xl': selectValue !== value,
      'h-40vw sm:h-64': $slots.img,
    }"
    @click="$emit('input', selectValue)"
  >
    <div v-if="$slots.img" class="w-full">
      <div class="w-20 h-20 sm:w-24 sm:h-24 mx-auto mb-2 sm:mb-4">
        <slot name="img" />
      </div>
    </div>
    <div class="font-medium text-center text-sm sm:text-base">
      <slot />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Boolean],
      default: null,
    },
    selectValue: {
      type: [String, Boolean],
      default: null,
    },
  },
};
</script>
